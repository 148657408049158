import React, { useState } from "react"

export const MenuContext = React.createContext()

export default function MenuProvider({ children }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  return (
    <MenuContext.Provider value={{ menuIsOpen, setMenuIsOpen }}>
      {children}
    </MenuContext.Provider>
  )
}
