import React from "react"
import { MenuContext } from "./MenuContext"

export default function NavToggle({ className }) {
  return (
    <MenuContext.Consumer>
      {context => (
        <button
          className={`c-nav-toggle ${className}`}
          onClick={() => context.setMenuIsOpen(!context.menuIsOpen)}
        >
          {!context.menuIsOpen ? (
            <svg
              width="34"
              height="21"
              xmlns="http://www.w3.org/2000/svg"
              className="c-nav-toggle__open"
            >
              <path
                d="M1.421.5H32.58m-31.159 10H32.58m-31.156 10h22.831"
                stroke="#FFF"
                fill="none"
                StrokeLinecap="square"
              />
            </svg>
          ) : (
            <svg
              width="34"
              height="21"
              xmlns="http://www.w3.org/2000/svg"
              className="c-nav-toggle__close"
            >
              <path
                d="M7 20.373L27.373 0m0 20.373L7 0"
                stroke="#FFF"
                stroke-width="2"
                fill="none"
                StrokeLinecap="square"
              />
            </svg>
          )}
        </button>
      )}
    </MenuContext.Consumer>
  )
}
