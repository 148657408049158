import React from "react"

export default function Intro({ title, html }) {
  return (
    <div className="c-container c-intro">
      <div className="o-grid">
        <div className="o-grid__item u-1/1 u-1/2@desktop c-intro__title">
          <h1 className="c-page-title">{title}</h1>
        </div>
        <div className="o-grid__item u-1/1 u-1/2@desktop c-intro__content">
          <div className="c-body" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </div>
  )
}
