import React from "react"

import Img from "gatsby-image"

export default function Paragraph({ node, equalHeight }) {
  const {
    frontmatter: { title, image, image_alt, info_text, html_id },
    html,
  } = node
  return (
    <div
      className={`o-grid c-paragraph ${
        equalHeight ? "c-paragraph--equal-height" : ""
      }
      
      ${info_text ? "c-paragraph--has-infobox" : ""}
      
      `}
    >
      <div
        id={html_id}
        className="o-grid__item u-1/1 u-1/2@desktop c-paragraph__image"
      >
        <Img alt={image_alt || title} fluid={image.childImageSharp.fluid} />
        {info_text ? <div className="c-info-box">{info_text}</div> : null}
      </div>
      <div className="o-grid__item u-1/1 u-1/2@desktop c-paragraph__content">
        <h2 className="c-paragraph__title">{title}</h2>
        <div className="c-body" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  )
}
