import React from "react"

import logo from "../img/recon_logo.svg"

import { Link } from "gatsby-plugin-intl"
import NavToggle from "./NavToggle"

import SelectLanguage from "./SelectLanguage"
import { FormattedMessage, injectIntl } from "react-intl"

const navItems = [
  { label: "home", to: "/" },
  { label: "about", to: "/over-ons" },
  { label: "asbestos", to: "/asbest" },
  { label: "activities", to: "/activiteiten" },
  { label: "contact", to: "/contact" },
]

const Header = ({ intl }) => {
  return (
    <header className="l-header">
      <div className="l-header__inner">
        <Link className="c-site-logo" to="/">
          <img src={logo} alt="Recon Damage Solutions" />
        </Link>
        <nav className="c-nav">
          {navItems.map(item => (
            <Link activeClassName="is-active" to={item.to} key={item.label}>
              <FormattedMessage id={item.label} />
            </Link>
          ))}
        </nav>
        <div className="c-quick-links">
          <div className="c-quick-links__primary">
            <a href="tel:080030075" className="c-quick-links__baseline">
              <FormattedMessage id="247" /> — 
              <span className="non-critical">0800 300 75</span>
            </a>

            <a
              className="c-button c-button--block c-button--main c-button--contact"
              href="mailto:asbest@recondamage.be"
            >
              <FormattedMessage id="request_invoice" />
            </a>
          </div>
          <div className="c-quick-contact">
            <a href="tel:+3223312347">+32 23312347</a>
            <a href="mailto:asbest@recondamage.be">mail</a>
          </div>
          <SelectLanguage />
          <NavToggle />
        </div>
      </div>
    </header>
  )
}

export default injectIntl(Header)
