import React from "react"

import { Link } from "gatsby-plugin-intl"
import { MenuContext } from "./MenuContext"
import NavToggle from "./NavToggle"

import { FormattedMessage, injectIntl } from "react-intl"

const navItems = [
  { label: "home", to: "/" },
  { label: "about", to: "/over-ons" },
  { label: "asbestos", to: "/asbest" },
  { label: "activities", to: "/activiteiten" },
  { label: "contact", to: "/contact" },
]

export default function OffcanvasNav() {
  return (
    <MenuContext.Consumer>
      {context => (
        <nav
          className={`c-offcanvas-nav ${context.menuIsOpen ? "is-open" : ""}`}
        >
          <div
            className="c-offcanvas-nav__overlay"
            onClick={() => context.setMenuIsOpen(false)}
          />
          <div className="c-offcanvas-nav__menu js-nav">
            <NavToggle className="c-nav-toggle--fixed" />
            <ul className="c-offcanvas-nav__list">
              {navItems.map(item => (
                <li key={item.label}>
                  <Link activeClassName="is-active" to={item.to}>
                    <FormattedMessage id={item.label} />
                  </Link>
                </li>
              ))}
            </ul>

            <div className="c-offcanvas-nav__info">
              <div className="o-grid">
                <div className="o-grid__item u-1/1 u-1/2@desktop">
                  <span className="u-brand-color">T</span>
                  +32 23312347
                  <br />
                  <span className="u-brand-color">F</span>
                  +32 23312787
                  <br />
                  <span className="u-brand-color">E</span>
                  <a href="mailto:asbest@recondamage.be">
                    asbest@recondamage.be
                  </a>
                </div>
                <div className="o-grid__item u-1/1 u-1/2@desktop">
                  <span className="u-brand-color">
                    24/7
                    <br />
                    bereikbaar /<br />
                    joignable
                  </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </MenuContext.Consumer>
  )
}
