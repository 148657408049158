import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import SEO from "../components/SEO"
import Intro from "../components/Intro"
import ParagraphList from "../components/ParagraphList"
import { injectIntl } from "react-intl"

const AboutPage = ({ intl }) => {
  const rawData = useStaticQuery(graphql`
    {
      nlParagraphs: allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "activity" }, langKey: { eq: "nl" } }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              html_id: id
              image {
                childImageSharp {
                  fluid(maxWidth: 604, maxHeight: 593) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      frParagraphs: allMarkdownRemark(
        filter: {
          frontmatter: { type: { eq: "activity" }, langKey: { eq: "fr" } }
        }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              html_id: id
              image {
                childImageSharp {
                  fluid(maxWidth: 604, maxHeight: 593) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }

      nl: markdownRemark(
        frontmatter: { id: { eq: "activities" }, langKey: { eq: "nl" } }
      ) {
        html
        frontmatter {
          title
        }
      }
      fr: markdownRemark(
        frontmatter: { id: { eq: "activities" }, langKey: { eq: "fr" } }
      ) {
        html
        frontmatter {
          title
        }
      }
    }
  `)

  const { edges: paragraphs } = rawData[`${intl.locale}Paragraphs`]

  const {
    frontmatter: { title },
    html,
  } = rawData[intl.locale]

  return (
    <>
      <SEO title={title} />

      <Layout>
        <div className="c-page-wrapper">
          <Intro html={html} title={title} />
          <ParagraphList data={paragraphs} />
        </div>
      </Layout>
    </>
  )
}

export default injectIntl(AboutPage)
