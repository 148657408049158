import React from "react"
import Paragraph from "./Paragraph"

export default function ParagraphList({ data }) {
  return (
    <div className="c-container ">
      <div className="c-paragraph-list">
        {data.map(({ node }) => (
          <Paragraph node={node} />
        ))}
      </div>
    </div>
  )
}
