import React from "react"

import logo from "../img/recon_logo.svg"
import { FormattedMessage } from "gatsby-plugin-intl"

export default function Contact() {
  return (
    <div className="c-bottom" id="contact">
      <div className="c-bottom__inner">
        <div className="c-bottom__cta">
          <a
            className="c-button c-button--large"
            href="mailto:asbest@recondamage.be"
          >
            <FormattedMessage id="request_invoice" />
          </a>
        </div>
        <div className="o-grid">
          <div className="o-grid__item u-1/1 u-4/12@desktop">
            <div className="c-bottom-text">
              <span className="u-brand-color">RECON Damage Solutions /</span>
              <br />
              Rue Golden Hopestraat 17B,
              <br />
              1620 Drogenbos
            </div>
          </div>
          <div className="o-grid__item u-1/1 u-3/12@desktop">
            <div className="c-bottom-text">
              <span className="u-brand-color">T</span> +32 23312347
              <br />
              <span className="u-brand-color">F</span> +32 23312787
              <br />
              <span className="u-brand-color">E</span>{" "}
              <a href="mailto:asbest@recondamage.be">asbest@recondamage.be</a>
              <br />
            </div>
          </div>
          <div className="o-grid__item u-1/1 u-3/12@desktop">
            <div className="c-bottom-text">
              <span className="u-brand-color">
                24/7
                <br />
                bereikbaar /<br />
                joignable
              </span>
            </div>
          </div>
          <div className="o-grid__item u-1/1 u-2/12@desktop">
            <div className="c-recon-icon">
              <img
                alt="Recon Damage Solutions"
                width="161"
                height="61"
                src={logo}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
