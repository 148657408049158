import React from "react"

export default function Footer() {
  return (
    <footer className="l-footer">
      <div className="l-footer__inner">
        <div className="c-footer-text">
          © {new Date().getFullYear()} RECON Damage Solutions
          {/* - <a href="disclaimer.html">Disclaimer</a> */}
        </div>
      </div>
    </footer>
  )
}
