/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"

import PropTypes from "prop-types"

import "../scss/main.scss"

import Footer from "./Footer"
import Header from "./Header"
import Contact from "./Contact"
import OffcanvasNav from "./OffcanvasNav"
import MenuProvider from "./MenuContext"
import { injectIntl } from "react-intl"

const Layout = ({ children, intl }) => {
  return (
    <MenuProvider>
      <Header />
      <OffcanvasNav />
      {children}
      <Contact />
      <Footer />
    </MenuProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
